import getAxiosConfig from "../axios";

interface LoginInterface {
  username: string;
  password: string;
}

export function apiLogin(loginInterface: LoginInterface) {
  const { username, password } = loginInterface;
  return getAxiosConfig().post("token/", {
    username,
    password,
  });
}

// Logout
export const apiLogout = () => {
  localStorage.clear();
};
