/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { plainToClass } from "class-transformer";
import { useDispatch, useSelector } from "react-redux";
import "./login.scss";
import Alert from "../../core/Alert";
import { apiLogin } from "../../services/api/auth/login";
import { LockClosedIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import User from "../../services/api/auth/UserModel";
import { loginSuccess } from "../../services/api/auth/authAction";
import { Carousel } from "antd";
import Loading from "../../core/components/Loading";
import { Spinner } from "../../core/components/Spinner";

function LoginForm() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const selectedUser = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();

  const [errorMessage, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onEmailChanged = (event: any) => {
    setUsername(event.target.value);
  };

  const onPasswordChanged = (event: any) => {
    setPassword(event.target.value);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    apiLogin({
      username,
      password,
    })
      .then((response: any) => {
        setLoading(false);
        if (response?.response?.status === 401) {
          // setError(response.response.data.detail)
        } else {
          const credentials = response.data;
          localStorage.setItem("sig-pacofide-access", credentials.access);
          localStorage.setItem("sig-pacofide-refresh", credentials.refresh);
          let user = new User(0, "", "JohnDoe");
          localStorage.setItem("user", JSON.stringify(user));
          dispatch(loginSuccess(credentials));
          navigate("/");
        }
      })
      .catch((e) => {
        setLoading(false);
        setError("Identifiant ou de mot de passe incorrect");
        // TODO: Handle error
      });
  };

  const renderButtonContent = () => {
    return loading ? "Chargement..." : "Connexion";
  };

  return (
    <div className="relative">
      <div className="h-screen w-screen text-center">
        <Carousel autoplay>
          <div>
            <img
              className="h-screen w-screen object-cover"
              src="./assets/bg-login-2.jpeg"
              alt=""
            />
          </div>
          <div>
            <img
              className="h-screen w-screen object-cover"
              src="./assets/bg-login-1.png"
              alt=""
            />
          </div>
        </Carousel>
      </div>
      <div className="absolute top-0 bottom-0 left-0 right-0 logincontainer flex flex-col justify-start">
        <div className="mb-2 p-4 hidden sm:block">
          <h2
            className="max-w-[100rem] w-ful mx-auto my-0 text-center font-light text-xl font-normal tracking-[5px] text-slate-400 custom-title"
            style={{ color: "white" }}
          >
            PLATEFORME DE SUIVI DES INTERVENTIONS <br /> ET DES BENEFICIAIRES DU
            <span className="font-semibold"> P2AE</span>
          </h2>
        </div>
        <div className="grow flex flex-col justify-center">
          <div className="LoginPage max-h-full justify-items-center">
            <div className="app-features hidden sm:flex sm:flex-col sm:justify-center sm:block">
              <div className="features">
                <div>
                  <span>
                    <img src="./assets/planingIcon.svg" alt="PLAN_ICON" />
                  </span>
                  <span>Mapping des interventions des projets</span>
                </div>
                <div>
                  <span>
                    <img src="./assets/dashboard_layout_icon.svg" alt="FOLLOW_ICON" />
                  </span>
                  <span>Tableau de bord de suivi des indicateurs</span>
                </div>
                <div>
                  <span>
                    <img src="./assets/followIcon.svg" alt="FOLLOW_ICON" />
                  </span>
                  <span>Bibliothèque numérique</span>
                </div>
                <div>
                  <span>
                    <img src="./assets/libraryIcon.svg" alt="LIBRARY_ICON" />
                  </span>
                  <span>Cartothèque</span>
                </div>
                <div>
                  <span>
                    <img src="./assets/libraryIcon.svg" alt="LIBRARY_ICON" />
                  </span>
                  <span>Base de données des interventions</span>
                </div>
                <div>
                  <span>
                    <img src="./assets/libraryIcon.svg" alt="LIBRARY_ICON" />
                  </span>
                  <span>Liste des acteurs</span>
                </div>
              </div>
            </div>

            <div className="login-space w-screen sm:w-fit p-4">
              <div className="login-container px-0 sm:px-4 py-4">
                <div className="flex w-full justify-center gap-2 mb-2 partners">
                  <img
                    src="./assets/repBenin.png"
                    alt="Rep.Bénin"
                    className="w-20 h-auto"
                  />
                  <img
                    src="./assets/logo-p2ae.png"
                    alt="P2AE"
                    className="w-20 sm:w-32 h-auto"
                  />
                  <img
                    src="./assets/worlBank_logo.svg"
                    alt="BM"
                    className="w-20 sm:w-32 h-auto"
                  />
                </div>

                <form onSubmit={onSubmit}>
                  <div>
                    <label htmlFor="">
                      <img
                        src="./assets/user_icon.svg"
                        alt="USER_ICON"
                        width={15}
                        height={15}
                      />
                    </label>
                    <input
                      type="text"
                      placeholder="Identifiant"
                      id="username"
                      name="username"
                      value={username}
                      onChange={onEmailChanged}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="">
                      <img
                        src="./assets/lock_icon.svg"
                        alt="LOCK_ICON"
                        width={15}
                        height={15}
                      />
                    </label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Mot de passe"
                      required
                      value={password}
                      onChange={onPasswordChanged}
                    />
                  </div>
                  <Link to="#">Mot de passe oublié?</Link>
                  <div className="submit-button-container">
                    <button type="submit">{renderButtonContent()}</button>
                  </div>
                </form>
              </div>

              <div className="developers-firm">
                <div>
                  <span>DES Consulting</span>
                  <Link to="#">
                    <img
                      src="./assets/gotoLink.svg"
                      alt="GO_TO"
                      width={15}
                      height={15}
                    />
                  </Link>
                </div>
                <div>
                  <img src="./assets/2mkouas.svg" width={60} alt="" />
                  <Link to="#">
                    <img
                      src="./assets/gotoLink.svg"
                      alt="GO_TO"
                      width={15}
                      height={15}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="relative">
    //   <div className="h-screen w-screen text-center">
    //     <Carousel autoplay>
    //       <div>
    //         <img
    //           className="h-screen w-screen object-cover"
    //           src="./assets/ananas.jpg"
    //           alt=""
    //         />
    //       </div>
    //       <div>
    //         <img
    //           className="h-screen w-screen object-cover"
    //           src="./assets/anacarde.jpg"
    //           alt=""
    //         />
    //       </div>
    //     </Carousel>
    //   </div>
    //   <div className="flex pt-6 login-header">
    //     <h2
    //       className="max-w-[100rem] w-ful mx-auto my-0 text-center font-light text-xl font-normal tracking-[5px] text-slate-400 custom-title"
    //       style={{ color: "white" }}
    //     >
    //       PLATEFORME DE GOUVERNANCE TERRITORIALE DES INTERVENTIONS DES FILIERES
    //       AGRICOLES DE
    //       <span className="font-semibold"> PACOFIDE</span>
    //     </h2>
    //   </div>
    //   <div className="LoginPage absolute top-0 bottom-0 left-0 right-0">
    //     <div className="app-features hidden sm:flex sm:flex-col sm:justify-center sm:block">
    //       <div className="features">
    //         <div>
    //           <span>
    //             <img src="./assets/planingIcon.svg" alt="PLAN_ICON" />
    //           </span>
    //           <span>Mapping des interventions des projets</span>
    //         </div>
    //         <div>
    //           <span>
    //             <img src="./assets/dashboard_layout_icon.svg" alt="FOLLOW_ICON" />
    //           </span>
    //           <span>Tableau de bord de suivi des activités</span>
    //         </div>
    //         <div>
    //           <span>
    //             <img src="./assets/followIcon.svg" alt="FOLLOW_ICON" />
    //           </span>
    //           <span>Bibliothèque numérique</span>
    //         </div>
    //         <div>
    //           <span>
    //             <img src="./assets/libraryIcon.svg" alt="LIBRARY_ICON" />
    //           </span>
    //           <span>Carthothèque</span>
    //         </div>
    //         <div>
    //           <span>
    //             <img src="./assets/libraryIcon.svg" alt="LIBRARY_ICON" />
    //           </span>
    //           <span>Base de données des activités par filière</span>
    //         </div>
    //         <div>
    //           <span>
    //             <img src="./assets/libraryIcon.svg" alt="LIBRARY_ICON" />
    //           </span>
    //           <span>Liste des acteurs</span>
    //         </div>
    //       </div>
    //     </div>

    //     <div className="login-space w-screen sm:w-fit p-4">
    //       <div className="login-container">
    //         <div className="flex w-full justify-center gap-2 mb-2 partners">
    //           <img
    //             src="./assets/repBenin.png"
    //             alt="Rep.Bénin"
    //             className="w-20 sm:w-32 h-auto"
    //           />
    //           <img
    //             src="./assets/paco.png"
    //             alt="PACOFIDE"
    //             className="w-20 sm:w-32 h-auto"
    //           />
    //           <img
    //             src="./assets/worlBank_logo.svg"
    //             alt="BM"
    //             className="w-20 sm:w-32 h-auto"
    //           />
    //         </div>

    //         <form onSubmit={onSubmit}>
    //           <div>
    //             <label htmlFor="">
    //               <img
    //                 src="./assets/user_icon.svg"
    //                 alt="USER_ICON"
    //                 width={15}
    //                 height={15}
    //               />
    //             </label>
    //             <input
    //               type="text"
    //               placeholder="Identifiant"
    //               id="username"
    //               name="username"
    //               value={username}
    //               onChange={onEmailChanged}
    //               required
    //             />
    //           </div>
    //           <div>
    //             <label htmlFor="">
    //               <img
    //                 src="./assets/lock_icon.svg"
    //                 alt="LOCK_ICON"
    //                 width={15}
    //                 height={15}
    //               />
    //             </label>
    //             <input
    //               type="password"
    //               name="password"
    //               placeholder="Mot de passe"
    //               required
    //               value={password}
    //               onChange={onPasswordChanged}
    //             />
    //           </div>
    //           <Link to="#">Mot de passe oublié?</Link>
    //           <div className="submit-button-container">
    //             <button type="submit">{renderButtonContent()}</button>
    //           </div>
    //         </form>
    //       </div>

    //       <div className="developers-firm">
    //         <div>
    //           <span>DES Consulting</span>
    //           <Link to="#">
    //             <img src="./assets/gotoLink.svg" alt="GO_TO" width={15} height={15} />
    //           </Link>
    //         </div>
    //         <div>
    //           <img src="./assets/2mkouas.svg" width={60} alt="" />
    //           <Link to="#">
    //             <img src="./assets/gotoLink.svg" alt="GO_TO" width={15} height={15} />
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default LoginForm;
