import BaseModel from "./BaseModel";

export default class Composante extends BaseModel {
  code: string;

  constructor(id: number, name: string, code: string) {
    super(id, name);
    this.id = id;
    this.name = name;
    this.code = code;
  }

  static fromJson(json: any): BaseModel {
    return new this(json.id, json.name, json.code);
  }

  static toSelectOption(values: Composante[]): any {
    return values.map((value) => {
      return {
        value: value.id,
        label: value.code || value.name,
      };
    });
  }
}
