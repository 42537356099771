import { Select } from "antd";

interface SelectComponentInterface {
  customPlaceHolder?: string;
  options: any;
  onChange?: (event: any) => void;
  className?: string;
  value?: any;
  multiple?: boolean;
}

export default function SelectComponent(props: SelectComponentInterface) {
  const { customPlaceHolder, options, onChange, className, value, multiple } = props;

  return (
    <Select
      value={value}
      style={{ width: 200, maxWidth: 200 }}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder={customPlaceHolder ?? "Sélectionner une option"}
      optionFilterProp="children"
      onChange={onChange}
      options={options}
      className={className}
      mode={multiple ? "multiple" : undefined}
    />
  );
}
