/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { EventHandler, useEffect, useRef, useState } from "react";
import { defaultFilieres } from "../constants";
import { requestHeader } from "../../services/api/auth/useUser";
import IndicateurAnalyseButton from "../../core/components/IndicateurAnalyseButton";
import ModalAnalyse from "../../core/components/ModalAnalyse";
import Filiere from "../../models/Filiere_model";
import BookIcon from "@mui/icons-material/BookOutlined";
import MapIcon from "@mui/icons-material/MapOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import { API_URL } from "../../services/api/axios";
import { getFromApiFunction } from "../../services/api/apiFunctions";

export function useLocalStorageState(key: string, defaultValue: any = undefined) {
  const [state, setState] = useState(() => {
    let value;
    try {
      const keyValue = localStorage.getItem(key);
      if (keyValue) {
        value = JSON.parse(keyValue) || defaultValue;
      } else {
        value = defaultValue;
      }
    } catch (error) {
      value = defaultValue;
    }
    return value;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

const excel_accept =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export function downloadFile(
  endPoint: string,
  fileName: string,
  accept = excel_accept,
) {
  const headers: any = requestHeader();
  if (accept !== "") headers.Accept = accept;
  fetch(`${API_URL}/${endPoint}`, { headers })
    .then((result) => {
      if (!result.ok) {
        throw Error(result.statusText);
      }
      console.log(result);
      return result.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", fileName);
      console.log(link);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export default function Navbar(props: any) {
  const { selectedFiliere, setSelectedFiliere, setSelectedIntervention } = props;

  const [openDownloads, setOpenDownloads] = useState(false);
  const [isAnalyzeOpen, setIsAnalyzeOpen] = useState(false);
  const [filiereVisible, setFiliereVisible] = useState(false);
  const [me, setMe] = useState<any>(null);

  const filieresRef = useRef<any>(null);
  const mobileMenu = useRef<any>(null);

  const [filiereComponent, setFiliereComponent] = useState<any>(null);

  const navigate = useNavigate();

  const location = useLocation();

  const closeAnalyse = () => {
    setIsAnalyzeOpen(false);
    // setIsAnalyseOpen(false);
  };
  const openAnalyse = (filiere: Filiere) => {
    // setSelectedFiliere(filiere);
    setIsAnalyzeOpen(true);
    switchMobileMenu();
  };

  function getFiliereFromValue(value: any) {
    return defaultFilieres.find((filiere) => filiere.value === value);
  }

  function selectFiliere(event: any, value: any) {
    setFiliereVisible(false);
    setSelectedFiliere(value);
  }

  useEffect(() => {
    getFromApiFunction("users/me").then((response) => setMe(response.data));
  }, []);

  useEffect(() => {
    setFiliereComponent(
      getFiliereFromValue(selectedFiliere)?.component({
        width: 24,
        height: 24,
        fill: "white",
      }),
    );
    console.log(selectedFiliere);
  }, [selectedFiliere]);

  useEffect(() => {
    if (!filieresRef.current) return;
    if (filiereVisible) {
      filieresRef.current.classList.add("group-hover:grid");
    } else {
      filieresRef.current?.classList.remove("group-hover:grid");
    }
  }, [filiereVisible]);

  const switchMobileMenu = function () {
    if (!mobileMenu.current) return;
    if (mobileMenu.current.classList.contains("group-hover:grid")) {
      mobileMenu.current.classList.remove("group-hover:grid");
    } else {
      mobileMenu.current.classList.add("group-hover:grid");
    }
  };

  const navigateAndHide = function (url: string) {
    switchMobileMenu();
    navigate(url);
  };

  return (
    <>
      <div className={`${location.pathname === "/" ? "hidden" : ""}`}>
        <nav className="fixed z-[9999] w-full bg-blue-900">
          <div className="max-w-[1400px] mx-auto my-0 px-4 md:px-10 flex items-center justify-between">
            {/* <span className="display-none md:display-block" style={{ width: "200px" }}>
            <img
              className=""
              src="./assets/logo-p2ae.png" style={{ marginTop: "-27%" }} />
            </span> */}
            {/* <div className="flex items-center space-x-4 text-white text-base md:text-xl">
              Filière
              <div
                className="transition-pacofide border-b-[3px] border-transparent hover:border-white group relative"
                onMouseEnter={() => {
                  setFiliereVisible(true);
                }}
              >
                <a
                  href="#"
                  className="flex items-center px-2 py-4 space-x-2 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                  onClick={() => {
                    setFiliereVisible(true);
                  }}
                >
                  {selectedFiliere !== "" ? (
                    filiereComponent
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style={{ fill: "currentColor" }}
                    >
                      <path d="M3 3v17a1 1 0 0 0 1 1h17v-2H5V3H3z"></path>
                      <path d="M15.293 14.707a.999.999 0 0 0 1.414 0l5-5-1.414-1.414L16 12.586l-2.293-2.293a.999.999 0 0 0-1.414 0l-5 5 1.414 1.414L13 12.414l2.293 2.293z"></path>
                    </svg>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ fill: "rgba(255, 255, 255, 1)" }}
                    className="pacofide-nav-icon transition-pacofide group-hover:rotate-180"
                  >
                    <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
                  </svg>
                </a>
                <ul
                  className="pacofide-nav-dropdown-menu group-hover:pointer-events-auto group-hover:top-[59px] group-hover:sm:top-[67px] z-[9999999]"
                  style={{ width: "200px" }}
                  ref={filieresRef}
                >
                  {defaultFilieres.map((filiere: Filiere, index: number) => {
                    return (
                      <li
                        key={`${filiere.id}-${index}`}
                        className="pacofide-nav-dropdown-item"
                        onClick={(event) => selectFiliere(event, filiere.value)}
                      >
                        <a
                          // href={}
                          className="flex flex-col items-center justify-center p-2 space-x-2 rounded-md hover:bg-gray-300 transition-pacofide"
                        >
                          <span className="flex items-center justify-center bg-gray-100 rounded-full w-11 h-11">
                            {<filiere.component />}
                          </span>
                          <span className="pacofide-nav-dropdown-link-inner">
                            {filiere.name}
                          </span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div> */}
            <a href="#" className="navbar-brand">
              P2AE
            </a>

            <div className="flex items-center mr-8">
              {/* Navbar to be displayed on large screen */}
              <ul className="items-center hidden md:flex">
                <li className="transition-pacofide border-b-[3px] border-transparent hover:border-white">
                  <a
                    className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                    onClick={() => navigate("/")}
                    title="Accueil"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style={{ fill: "currentColor" }}
                    >
                      <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path>
                    </svg>
                  </a>
                </li>
                <li className="transition-pacofide border-b-[3px] border-transparent hover:border-white">
                  <a
                    className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                    onClick={() => navigate("mapping")}
                    title="Mapping"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style={{ fill: "currentColor" }}
                    >
                      <path d="M19 4h-3V2h-2v2h-4V2H8v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM5 20V7h14V6l.002 14H5z"></path>
                      <path d="M7 9h10v2H7zm0 4h5v2H7z"></path>
                    </svg>
                  </a>
                </li>
                <li className="transition-pacofide border-b-[3px] border-transparent hover:border-white">
                  <a
                    className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                    onClick={() => openAnalyse(selectedFiliere)}
                    title="Tableau de Bord"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style={{ fill: "currentColor" }}
                    >
                      <path d="M3 3v17a1 1 0 0 0 1 1h17v-2H5V3H3z"></path>
                      <path d="M15.293 14.707a.999.999 0 0 0 1.414 0l5-5-1.414-1.414L16 12.586l-2.293-2.293a.999.999 0 0 0-1.414 0l-5 5 1.414 1.414L13 12.414l2.293 2.293z"></path>
                    </svg>
                  </a>
                </li>
                {me?.is_superuser && (
                  <li className="transition-pacofide border-b-[3px] border-transparent hover:border-white">
                    <a
                      id="download"
                      className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 lc-modal-show transition-pacofide"
                      onClick={() => setOpenDownloads(true)}
                      title="Base de données"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        style={{ fill: "currentColor" }}
                      >
                        <path d="M20 17V7c0-2.168-3.663-4-8-4S4 4.832 4 7v10c0 2.168 3.663 4 8 4s8-1.832 8-4zM12 5c3.691 0 5.931 1.507 6 1.994C17.931 7.493 15.691 9 12 9S6.069 7.493 6 7.006C6.069 6.507 8.309 5 12 5zM6 9.607C7.479 10.454 9.637 11 12 11s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2V9.607zM6 17v-2.393C7.479 15.454 9.637 16 12 16s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2z"></path>
                      </svg>
                    </a>
                  </li>
                )}
                <li className="transition-pacofide border-b-[3px] border-transparent hover:border-white">
                  <a
                    href="#"
                    className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                    onClick={() => navigate("reports")}
                    title="Bibliothèque"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style={{ fill: "currentColor" }}
                    >
                      <path d="M19 2.01H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.998 5 19.815 5 19.01c0-.101.009-.191.024-.273.112-.575.583-.717.987-.727H20c.018 0 .031-.009.049-.01H21V4.01c0-1.103-.897-2-2-2zm0 14H5v-11c0-.806.55-.988 1-1h7v7l2-1 2 1v-7h2v12z"></path>
                    </svg>
                  </a>
                </li>
                <li className="transition-pacofide border-b-[3px] border-transparent hover:border-white">
                  <a
                    href="#"
                    className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                    onClick={() => navigate("maplibrary")}
                    title="Cartothèque"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style={{ fill: "currentColor" }}
                    >
                      <path d="m21.447 6.105-6-3a1 1 0 0 0-.895 0L9 5.882 3.447 3.105A1 1 0 0 0 2 4v13c0 .379.214.725.553.895l6 3a1 1 0 0 0 .895 0L15 18.118l5.553 2.776a.992.992 0 0 0 .972-.043c.295-.183.475-.504.475-.851V7c0-.379-.214-.725-.553-.895zM10 7.618l4-2v10.764l-4 2V7.618zm-6-2 4 2v10.764l-4-2V5.618zm16 12.764-4-2V5.618l4 2v10.764z"></path>
                    </svg>
                  </a>
                </li>
                <li className="transition-pacofide border-b-[3px] border-transparent hover:border-white">
                  <a
                    href="#"
                    className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                    onClick={() => navigate("actors")}
                    title="Acteurs"
                  >
                    <UserGroupIcon className="w-6 h-6 text-white" />
                  </a>
                </li>
              </ul>

              {/* Navigation on small screen */}
              <div className="block md:hidden transition-pacofide border-b-[3px] border-transparent hover:border-white group">
                <a
                  href="#"
                  className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                  onClick={() => {
                    switchMobileMenu();
                  }}
                  onPointerLeave={() =>
                    mobileMenu.current?.classList.remove("group-hover:grid")
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ fill: "currentColor" }}
                  >
                    <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                  </svg>
                </a>
                <ul
                  ref={mobileMenu}
                  className="pacofide-nav-dropdown-menu group-hover:pointer-events-auto group-hover:top-[59px] group-hover:sm:top-[67px] sm:left-[72%]"
                >
                  <li className="pacofide-nav-dropdown-item">
                    <a
                      onClick={() => navigateAndHide("/")}
                      className="flex flex-col items-center justify-center p-2 space-x-2 rounded-md hover:bg-gray-300 transition-pacofide"
                    >
                      <span className="flex items-center justify-center bg-gray-100 rounded-full w-11 h-11">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style={{ fill: "currentColor" }}
                          className="w-8 h-8 text-slate-500"
                        >
                          <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path>
                        </svg>
                      </span>
                      <span className="pacofide-nav-dropdown-link-inner">Accueil</span>
                    </a>
                  </li>
                  <li className="pacofide-nav-dropdown-item">
                    <a
                      onClick={() => navigateAndHide("mapping")}
                      className="flex flex-col items-center justify-center p-2 space-x-2 rounded-md hover:bg-gray-300 transition-pacofide"
                    >
                      <span className="flex items-center justify-center bg-gray-100 rounded-full w-11 h-11">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style={{ fill: "currentColor" }}
                          className="w-8 h-8 text-slate-500"
                        >
                          <path d="M19 4h-3V2h-2v2h-4V2H8v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM5 20V7h14V6l.002 14H5z"></path>
                          <path d="M7 9h10v2H7zm0 4h5v2H7z"></path>
                        </svg>
                      </span>
                      <span className="pacofide-nav-dropdown-link-inner">Mapping</span>
                    </a>
                  </li>
                  <li className="pacofide-nav-dropdown-item">
                    <a
                      onClick={() => openAnalyse(selectedFiliere)}
                      className="flex flex-col items-center justify-center p-2 space-x-2 rounded-md hover:bg-gray-300 transition-pacofide"
                    >
                      <span className="flex items-center justify-center bg-gray-100 rounded-full w-11 h-11">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style={{ fill: "currentColor" }}
                          className="w-8 h-8 text-slate-500"
                        >
                          <path d="M3 3v17a1 1 0 0 0 1 1h17v-2H5V3H3z"></path>
                          <path d="M15.293 14.707a.999.999 0 0 0 1.414 0l5-5-1.414-1.414L16 12.586l-2.293-2.293a.999.999 0 0 0-1.414 0l-5 5 1.414 1.414L13 12.414l2.293 2.293z"></path>
                        </svg>
                      </span>
                      <span className="pacofide-nav-dropdown-link-inner">
                        Tableau de Bord
                      </span>
                    </a>
                  </li>
                  <li className="pacofide-nav-dropdown-item">
                    <a
                      onClick={() => {
                        setOpenDownloads(true);
                        switchMobileMenu();
                      }}
                      className="flex flex-col items-center justify-center p-2 space-x-2 rounded-md hover:bg-gray-300 transition-pacofide"
                    >
                      <span className="flex items-center justify-center bg-gray-100 rounded-full w-11 h-11">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style={{ fill: "currentColor" }}
                          className="w-8 h-8 text-slate-500"
                        >
                          <path d="M20 17V7c0-2.168-3.663-4-8-4S4 4.832 4 7v10c0 2.168 3.663 4 8 4s8-1.832 8-4zM12 5c3.691 0 5.931 1.507 6 1.994C17.931 7.493 15.691 9 12 9S6.069 7.493 6 7.006C6.069 6.507 8.309 5 12 5zM6 9.607C7.479 10.454 9.637 11 12 11s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2V9.607zM6 17v-2.393C7.479 15.454 9.637 16 12 16s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2z"></path>
                        </svg>
                      </span>
                      <span className="pacofide-nav-dropdown-link-inner">
                        Télécharger
                      </span>
                    </a>
                  </li>
                  <li className="pacofide-nav-dropdown-item">
                    <a
                      onClick={() => navigateAndHide("reports")}
                      className="flex flex-col items-center justify-center p-2 space-x-2 rounded-md hover:bg-gray-300 transition-pacofide"
                    >
                      <span className="flex items-center justify-center bg-gray-100 rounded-full w-11 h-11">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style={{ fill: "currentColor" }}
                          className="w-8 h-8 text-slate-500"
                        >
                          <path d="M19 2.01H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.998 5 19.815 5 19.01c0-.101.009-.191.024-.273.112-.575.583-.717.987-.727H20c.018 0 .031-.009.049-.01H21V4.01c0-1.103-.897-2-2-2zm0 14H5v-11c0-.806.55-.988 1-1h7v7l2-1 2 1v-7h2v12z"></path>
                        </svg>
                      </span>
                      <span className="pacofide-nav-dropdown-link-inner">
                        Documents
                      </span>
                    </a>
                  </li>
                  <li className="pacofide-nav-dropdown-item">
                    <a
                      href="#"
                      className="flex flex-col items-center justify-center p-2 space-x-2 rounded-md hover:bg-gray-300 transition-pacofide"
                      onClick={() => navigateAndHide("maplibrary/")}
                    >
                      <span className="flex items-center justify-center bg-gray-100 rounded-full w-11 h-11">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style={{ fill: "currentColor" }}
                          className="w-8 h-8 text-slate-500"
                        >
                          <path d="m21.447 6.105-6-3a1 1 0 0 0-.895 0L9 5.882 3.447 3.105A1 1 0 0 0 2 4v13c0 .379.214.725.553.895l6 3a1 1 0 0 0 .895 0L15 18.118l5.553 2.776a.992.992 0 0 0 .972-.043c.295-.183.475-.504.475-.851V7c0-.379-.214-.725-.553-.895zM10 7.618l4-2v10.764l-4 2V7.618zm-6-2 4 2v10.764l-4-2V5.618zm16 12.764-4-2V5.618l4 2v10.764z"></path>
                        </svg>
                      </span>
                      <span className="pacofide-nav-dropdown-link-inner">
                        Cartothèque
                      </span>
                    </a>
                  </li>
                  <li className="pacofide-nav-dropdown-item">
                    <a
                      href="#"
                      className="flex flex-col items-center justify-center p-2 space-x-2 rounded-md hover:bg-gray-300 transition-pacofide"
                      onClick={() => navigateAndHide("actors/")}
                    >
                      <UserGroupIcon className="w-6 h-6 text-slate-500" />
                      <span className="pacofide-nav-dropdown-link-inner">Acteurs</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* Here comes download modal */}
      <div
        className={`${
          openDownloads ? "" : "hidden"
        } lc-modal fixed left-1/2 top-1/2 -translate-x-2/4 -translate-y-2/4 max-w-[350px] sm:max-w-full sm:min-w-[400px] bg-white p-5 z-[99999] transition-pacofide`}
        data-lc-target="download"
      >
        <span
          className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
          data-lc-target="#download"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "currentColor" }}
            onClick={() => setOpenDownloads(false)}
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
          </svg>
        </span>

        <div className="mt-14 space-y-12 max-h-[564px] overflow-auto">
          <div className="relative">
            <h3 className="text-xl font-medium text-slate-500">
              Base de données {getFiliereFromValue(selectedFiliere)?.name}
            </h3>
            <div>
              <div className="block w-full overflow-x-auto rounded-md">
                <table className="w-full mt-6 border border-collapse border-slate-400">
                  <tbody>
                    <tr className="text-xs text-slate-500 odd:bg-white even:bg-slate-50">
                      <td className="px-3 py-2.5 font-medium border border-slate-300 whitespace-nowrap">
                        Activités PACOFIDE & autres projets
                      </td>
                      <td className="px-3 py-2.5 border border-slate-300">
                        <div className="flex items-center justify-center">
                          <button
                            type="button"
                            className="bg-blue-500 hover:bg-blue-600 shadow-md text-white text-[12px] font-medium px-4 py-1.5 rounded-md transition-pacofide"
                            onClick={() =>
                              downloadFile(
                                `activities/?filiere=${selectedFiliere}`,
                                "Activités.xlsx",
                              )
                            }
                          >
                            Télécharger
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          openDownloads ? "" : "hidden"
        } lc-modal-overlay fixed left-0 top-0 h-full w-full bg-black/50 z-[9999] transition-pacofide`}
        data-lc-target="download"
        onClick={() => setOpenDownloads(false)}
      ></div>

      <div
        className={`overlay-inner absolute right-[40px] top-[150px] z-500 ${
          location.pathname == "/graph" ? "" : "hidden"
        }`}
      >
        <IndicateurAnalyseButton onClick={openAnalyse} />
      </div>
      <ModalAnalyse
        isOpen={isAnalyzeOpen}
        onClose={closeAnalyse}
        selectIntervention={setSelectedIntervention}
      />
    </>
  );
}
