import { useEffect, useState } from "react";
import Composante from "../../models/Intervention_model";
import { getInterventions } from "../../services/api/data/intervention_services";
import { Link, useNavigate } from "react-router-dom";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function ModalAnalyse(props: any) {
  const { isOpen, onClose, selectIntervention } = props;
  const navigate = useNavigate();
  const [composantes, setInterventions] = useState<Composante[]>([]);

  const getIntervention = () => {
    getInterventions().then((response) => {
      setInterventions(Composante.fromJsonList(response.data.results));
    });
  };

  useEffect(() => {
    getIntervention();
  }, []);

  const handleNavigation = (composante: Composante) => {
    selectIntervention(composante);
    navigate("/graph", { state: { composante } });
    onClose();
  };

  return (
    <div
      className={`overlay-inner absolute top-[50px] right-0 md:right-[40px] md:top-[150px] ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="">
        <div
          className="lc-modal absolute right-0 top-0 w-screen md:w-[330px] sm:w-[400px] bg-black/50 p-5 z-[999] transition-pacofide"
          data-lc-target="overlay"
        >
          <button
            className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
            data-lc-target="#tracking"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: "currentColor" }}
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </button>
          <div className="space-y-12 max-h-[564px]">
            <div className="relative">
              <h3 className="text-base font-medium text-white">ODP/Composante</h3>
              <div className="mt-6 overflow-auto analyse-listing">
                <ul>
                  {composantes.map((intervention: Composante, index: number) => {
                    return (
                      <li key={`${intervention.id}-${intervention.name} `}>
                        <a
                          onClick={() => handleNavigation(intervention)}
                          // to={{ pathname: "/graph", }}
                          className="flex items-center justify-between px-2 py-2.5 border-t-[1px] border-b-[1px] bg-blue-50 hover:bg-transparent transition-pacofide"
                        >
                          <span className="text-xs text-blue-500 transition-pacofide">
                            {intervention.code}
                          </span>
                          <span className="text-slate-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              style={{ fill: "currentColor" }}
                            >
                              <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                            </svg>
                          </span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
