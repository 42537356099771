import { useState, useEffect } from "react";
import SelectComponent from "./SelectComponent";
import { getDepartement } from "../../services/api/data/departement_services";
import Departement, { Commune } from "../../models/Departement_model";
import Pole from "../../models/Pole_model";
import { getPole } from "../../services/api/data/pole_services";
import { getVillages } from "../../services/api/data/village_services";
import Village from "../../models/Village_model copy";
import Thematic from "../../models/Thematic";
import { getFromApiFunction } from "../../services/api/apiFunctions";

export default function IndicateurSuivi(props: any) {
  const {
    isOpen,
    onClose,
    actionSetMarkers,
    regionLayerSetter,
    setCommuneName,
    actionSetNetworkAxis,
  } = props;

  const [selectedInterventions, setSelectedInterventions] = useState<any>([]);

  const [years, setYears] = useState<any>([]);
  const [axisYears, setAxisYears] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<any>([]);

  const [communes, setCommunes] = useState<Commune[]>([]);
  const [selectedCommune, setSelectedCommune] = useState<any>();

  const [selectedPole, setSelectedPole] = useState<any>();
  const [departements, setDepartements] = useState<Departement[]>([]);
  const [selectedDepartement, setSelectedDepartement] = useState<any>();
  const [region, setRegion] = useState<"pole" | "departement">();

  const notNull = (value: any) => {
    return value !== null && value !== undefined;
  };

  const Interventions = {
    axe: "Réseau électrique",
    luminaire: "Luminaires",
    transformer: "Transformers",
  };

  useEffect(() => {
    Departement.fetchData().then((response: any) => {
      setDepartements(Departement.fromJsonList(response.data.results));
    });
    Commune.fetchData().then((response: any) => {
      setCommunes(Commune.fromJsonList(response.data.results));
    });
  }, []);

  useEffect(() => {
    setSelectedYear(undefined);
  }, [years]);

  useEffect(() => {
    if (!selectedInterventions) return;
    let interventions = [...selectedInterventions];

    if (interventions.includes("axe")) {
      // Pop "axe" from interventions
      interventions = interventions.filter((item: any) => item !== "axe");
      getFromApiFunction("/axis/years/").then((response: any) => {
        setAxisYears(response.data);
      });
    }

    if (!interventions.length) return;
    let params = new URLSearchParams();
    params.append("intervention_type", interventions.join(","));
    getFromApiFunction("/interventions/years/", params).then((response: any) => {
      setYears(response.data);
    });
  }, [selectedInterventions]);

  useEffect(() => {
    if (!selectedYear || !selectedDepartement || !selectedCommune) return;
    let params = new URLSearchParams([
      ["year", selectedYear],
      ["departement", selectedDepartement],
    ]);
    if (selectedCommune) {
      console.log(selectedCommune);
      params.append("commune", selectedCommune);
    }
    getFromApiFunction("/axis/", params).then((response: any) => {
      actionSetNetworkAxis(response.data.results);
    });

    let interventions = selectedInterventions.filter((item: any) => item !== "axe");
    params.append("intervention_type", interventions.join(","));
    getFromApiFunction("/interventions/", params).then((response: any) => {
      actionSetMarkers(response.data.results);
    });
  }, [selectedDepartement, selectedCommune]);

  return (
    <div
      className={`overlay-inner absolute left-[40px] top-[150px] ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="tracking-inner absolute">
        <div
          className="lc-modal absolute left-0 top-0 w-fit sm:w-[400px] bg-black/50 p-5 z-[999] transition-pacofide"
          data-lc-target="tracking"
        >
          <button
            className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-white text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
            data-lc-target="#tracking"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: "currentColor" }}
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </button>
          <div className="space-y-12">
            <form className="relative text-white">
              <h3 className="flex items-center justify-center space-x-4 text-base font-medium">
                Suivi des interventions
              </h3>
              <div className="space-y-6 mt-6 overflow-y-auto overflow-x-hidden max-h-[300px] md:max-h-none">
                <div className="sm:space-y-6 sm:block sm:w-[370px]">
                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">
                      Interventions
                    </span>

                    <SelectComponent
                      options={Object.entries(Interventions).map(([key, value]) => ({
                        label: value,
                        value: key,
                      }))}
                      onChange={setSelectedInterventions}
                      customPlaceHolder="Sélectionner une intervention"
                      className="w-full"
                      value={selectedInterventions}
                      multiple
                    />
                  </label>
                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">
                      Année d'intervention
                    </span>

                    <SelectComponent
                      options={
                        // Merge axisYears and years unique values
                        Array.from(new Set([...axisYears, ...years])).map((year) => ({
                          label: year,
                          value: year,
                        }))
                      }
                      onChange={setSelectedYear}
                      customPlaceHolder="Année"
                      className="w-full"
                      value={selectedYear}
                    />
                  </label>

                  {/* {region === "pole" && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">Pole</span>

                      <SelectComponent
                        options={Pole.toSelectOption(poles)}
                        onChange={(value) => changeVariable(value, 5)}
                        customPlaceHolder="Sélectionner un pole"
                        value={selectedPole}
                      />
                    </label>
                  )}*/}

                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">Departement</span>

                    <SelectComponent
                      options={Departement.toSelectOption(departements)}
                      onChange={setSelectedDepartement}
                      customPlaceHolder="Sélectionner un département"
                      value={selectedDepartement}
                    />
                  </label>

                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">Commune</span>

                    <SelectComponent
                      options={[Commune.default(), ...Commune.toSelectOption(communes)]}
                      onChange={setSelectedCommune}
                      customPlaceHolder="Sélectionner une commune"
                      value={selectedCommune}
                    />
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
