import { useState, useEffect } from "react";
import BarChart from "../graphs/graph_components/BarChart";
import { Button, Carousel } from "antd";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getFromApiFunction } from "../../services/api/apiFunctions";

export default function Home() {
  const [filiere, intervention, setFiliere] = useOutletContext<any>();
  const navigate = useNavigate();

  const [indicators, setIndicators] = useState([]);

  const moveToMapping = (filiereConst: any) => {
    setFiliere(filiereConst);
    navigate("/mapping");
  };

  useEffect(() => {
    getFromApiFunction(
      "/indicators/",
      new URLSearchParams([["composante", "null"]]),
    ).then((response) => {
      setIndicators(response.data.results);
    });
  }, []);

  return (
    <div className="home-background h-screen">
      <div className="max-w-[1400px] mx-auto my-0 h-full">
        <div className="pt-2 flex justify-center gap-2 items-center sm:flex-row flex-col">
          <span
            className="w-[200px] lg:w-auto !h-[67px] lg:!h-[120px]"
            style={{ overflow: "hidden" }}
          >
            <img
              className="h-[180px] lg:h-[300px]"
              src="./assets/logo-p2ae.png"
              style={{ marginTop: "-27%" }}
            />
          </span>
          <span className="max-w-[200px] w-[200px] !h-[67px] lg:!h-[120px] lg:w-auto bg-white wb-logo">
            <img src="./assets/world-bank.png" />
          </span>
        </div>
        <div className="flex pt-12 custom-header">
          <h2 className="w-ful mx-auto my-0 text-center font-light text-xl font-normal tracking-[2px] sm:tracking-[5px] text-slate-400 color-green !text-lg sm:!leading-10 sm:!text-[2rem] md:!leading-[2.75rem] md:!text-[2.5rem] custom-title">
            PLATEFORME DE SUIVI DES INTERVENTIONS <br /> ET DES BENEFICIAIRES DU
            <span className="font-semibold"> P2AE</span>
          </h2>
        </div>
        <div className="mt-12 mb-28 max-w-[80rem] bg-white rounded-t-lg block m-auto">
          <div className="">
            {/* <Carousel> */}
            <div className="sm:space-y-12 max-w-[70rem] mx-auto my-0 h-full">
              <div className="flex items-center justify-center space-x-9 p-7 lg:p-0">
                <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-0 lg:space-x-10">
                  {indicators.slice(0, 2).map((indicator: any) => (
                    <span className="w-[500px] hidden sm:block" key={indicator.id}>
                      <BarChart
                        graphName={indicator.name}
                        baseEndpoint="indicators_values"
                        statsUnitProps={"year"}
                        paramsProps={new URLSearchParams([["indicator", indicator.id]])}
                        color={"#ebab34"}
                        customHeigth={200}
                        // dataFilterHelper={(results: any) => {
                        //   // Return results ordered by label
                        //   return results.sort((a: any, b: any) => a.label > b.label);
                        // }}
                      />
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Button
            type="primary"
            style={{ backgroundColor: "blue", display: "block", margin: "auto" }}
            onClick={() => navigate("mapping")}
          >
            Vers le mapping
          </Button>
        </div>
      </div>
    </div>
  );
}
