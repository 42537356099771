import BaseModel from "./BaseModel";

export class Commune extends BaseModel {
  departement: number;
  pole: number;
  slug: string;
  projects_list: any;
  static url = "communes/";

  constructor(
    id: number,
    name: string,
    departement: number,
    pole: number,
    slug: string,
    projects_list: any,
  ) {
    super(id, name);
    this.departement = departement;
    this.pole = pole;
    this.slug = slug;
    this.projects_list = projects_list;
  }

  static fromJson(json: any): Commune {
    return new this(
      json.id,
      json.name,
      json.departement,
      json.pole,
      json.slug,
      json.projects_list,
    );
  }

  static toSelectOption(values: BaseModel[]): any {
    return values.map((value) => {
      return {
        value: value.name,
        label: value.name,
      };
    });
  }
}

export default class Departement extends BaseModel {
  projects_list: any;
  static url = "departements/";

  constructor(id: number, name: string, projects_list: any) {
    super(id, name);
    this.id = id;
    this.name = name;
    this.projects_list = projects_list;
  }

  static fromJson(json: any): Departement {
    return new this(json.id, json.name, json.projects_list);
  }

  static toSelectOption(values: BaseModel[]): any {
    return values.map((value) => {
      return {
        value: value.name,
        label: value.name,
      };
    });
  }
}
